function getTimeRemaining(endtime) {
    var t = Date.parse(endtime) - Date.parse(new Date());
    var seconds = Math.floor((t / 1000) % 60);
    var minutes = Math.floor((t / 1000 / 60) % 60);
    var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
    var days = Math.floor(t / (1000 * 60 * 60 * 24));
    return {
        'total': t,
        'days': days,
        'hours': hours,
        'minutes': minutes,
        'seconds': seconds
    };
}

function initializeClock(id, endtime) {
    var clock = document.getElementById(id);
    var daysSpan = clock.querySelector('.days');
    var hoursSpan = clock.querySelector('.hours');
    var minutesSpan = clock.querySelector('.minutes');
    var secondsSpan = clock.querySelector('.seconds');

    function updateClock() {
        var t = getTimeRemaining(endtime);

        daysSpan.innerHTML = pad(Math.max(t.days, 0), 2);
        hoursSpan.innerHTML = ('0' + Math.max(t.hours, 0)).slice(-2);
        minutesSpan.innerHTML = ('0' + Math.max(t.minutes, 0)).slice(-2);
        secondsSpan.innerHTML = ('0' + Math.max(t.seconds, 0)).slice(-2);

        if (t.total <= 0) {
            clearInterval(timeinterval);
        }
    }

    updateClock();
    var timeinterval = setInterval(updateClock, 1000);
}


function getDocSize() {
    var height = $(window).height();
    var width = $(window).width();
    return {width: width, height: height};
}

function pad(num, size) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
}

var deadline = new Date(Date.parse("Sun Sep 25 2016 16:00:00 GMT+0200"));
if($("#time").length > 0) initializeClock('time', deadline);



$(".da-headline").on('click', '.glyphicon', function() {
    $(this).toggleClass('glyphicon-chevron-down').toggleClass('glyphicon-chevron-up');
});